import React, { Suspense } from 'react';
const Snowfall = React.lazy(() => import('react-snowfall'));

const showSnowFall = (() => {
  const d = new Date();
  return (
    (d.getMonth() === 11 && d.getDate() > 20) ||
    (d.getMonth() === 0 && d.getDate() < 4)
  );
})();

const TemporarySnowfall = () => {
  const isSSR = typeof window === 'undefined';
  return (
    <>
      {!isSSR && showSnowFall && (
        <Suspense fallback={<></>}>
          <Snowfall style={{ zIndex: 2147483647 }} />
        </Suspense>
      )}
    </>
  );
};

export default TemporarySnowfall;
