import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby';

import Seo from '../components/seo';
import OutrunBackground from '../components/OutrunBackground';
import FritoLay from '../components/FritoLay';
import TemporarySnowfall from '../components/TemporarySnowfall';

import spark from '../images/spark.svg';

const GlobalStyles = createGlobalStyle`
  body {
    line-height: 1.4;
  }
`;

const SiteHeading = styled.div`
  padding: 40px 20px;

  @media only screen and (min-width: 768px) {
    padding: 40px;
  }

  h1 {
    font-size: calc(15px + 5vh);
    margin: 0;
    padding: 0;
    font-family: Montserrat;
    font-weight: 900;
    background: linear-gradient(
      cornflowerblue 30%,
      white,
      darkmagenta 60%,
      cornflowerblue 100%
    );
    background-size: contain;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.4);
    position: relative;
    text-align: center;

    animation: bg-shift 1.3s linear infinite alternate;

    @media only screen and (max-width: 767px) {
      font-size: calc(22px + 4vw);
    }

    &:before {
      content: attr(data-text);
      text-shadow: -1px -1px 1px #2989cc, -2px -2px 1px #2989cc,
        -3px -3px 1px #2989cc, 1px 1px 1px #000, 0px -1px 2px #000,
        -1px -2px 2px #000, 0 0 5px rgba(255, 255, 255, 1);
      opacity: 1;
      position: absolute;
      z-index: -1;
    }

    &:after {
      content: attr(data-text);
      background-image: linear-gradient(
        225deg,
        transparent 53%,
        white 55%,
        transparent 58%
      );
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-background-clip: text;
      z-index: 999;
      background-size: 400% 400%;
      animation: shine 10s ease infinite;
      animation-delay: var(--shine-delay, 0s);
      pointer-events: none;
    }
  }

  @keyframes bg-shift {
    0% {
      background-position: 0 -20px;
    }
    100% {
      background-position: 0 20px;
    }
  }

  @keyframes shine {
    0% {
      background-position: 0% 51%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 51%;
    }
  }

  .spark {
    margin-top: calc(4vh);
    margin-left: -0.3em;
    line-height: 0;
    position: absolute;
    width: 37px;
    height: 45px;
    z-index: 999;
    background-image: url(${spark});
    filter: blur(0.5px);
    transform: translateZ(0);
    animation: blip 5s 1.4s infinite ease-in-out;
  }

  @keyframes blip {
    0% {
      transform: scale(0);
    }
    5% {
      transform: scale(1.2) rotate(0deg);
    }
    6% {
      transform: scale(1) rotate(0deg);
    }
    8% {
      transform: scale(1) rotate(180deg);
    }
    100% {
      transform: scale(1) rotate(180deg);
    }
  }
`;

const CoinMeDaddy = styled.div`
  font-family: 'Press Start 2P';
  text-align: center;
  margin: 80px 30px 40px;

  a {
    display: inline-block;
    text-decoration: none;
    padding: 1em;
    color: white;
    cursor: url('data:image/x-icon;base64,AAACAAEAICAQAAAAAADoAgAAFgAAACgAAAAgAAAAQAAAAAEABAAAAAAAAAIAAAAAAAAAAAAAEAAAAAAAAAAAYJAAAAAAAPj4+AAAkMAAAMj4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEREREREREREREREREREREREREREREREREREREREREREREREREURERDMzERERERERERERERFEREQzMxERERERERERESJEREREREQzMxEREREREREiREREREREMzMREREREREiREREAABEREQzERERERERIkRERAAAREREMxERERERESJERAAzMwBERDMzEREREREiREQAMzMAREQzMxEREREiREREIkQzAERERDMRERERIkRERCJEMwBEREQzERERESJEREQiRDMAREREMxEREREiREREIkQzAERERDMRERERIkRERCJEMwBEREQzERERESJEREQiRDMAREREMxEREREiREREIkQzAERERDMRERERIkRERCJEMwBEREQzERERESJEREQiRDMAREREMxEREREiREREIkQzAERERDMRERERIkRERCJEMwBEREQzERERESJEREQiRDMAREREMxERERERIkREIkREAEREMzMRERERESJERCJERABERDMzEREREREiREREIiJEREQzERERERERIkRERCIiREREMxEREREREREiIkREREREMzMRERERERERIiJERERERDMzERERERERERERIiIiMzMRERERERERERERESIiIjMzERERERERERERERERERERERERERERERERERERERERERERERERERH/AA///wAP//AAAP/wAAD/wAAAP8AAAD/AAAA/wAAAPwAAAA8AAAAPAAAADwAAAA8AAAAPAAAADwAAAA8AAAAPAAAADwAAAA8AAAAPAAAADwAAAA8AAAAPAAAADwAAAA/AAAA/wAAAP8AAAD/AAAA/8AAA/3AAAP9/AA//HwAP/w=='),
      auto;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  div {
    color: #46aaff;
    pointer-events: none;
    margin-top: 20px;
    font-size: .85em;
    opacity: 0.242069;
    user-select: none;

    small {
      font-size: .6em;
    }
  }
`;

const IndexPage = () => {
  const [secretMessage, setSecretMessage] = useState('');
  useEffect(() => {
    setSecretMessage([
      'hwg &lt;3',
      'pfannkuchen sind liebchen',
      'big year for pullman!',
      'hot doctor pepper<br /><small>(with a thin slice of lemon)</small>',
      'mommy loves you! &lt;3',
      'li mu bai is here',
      'i think we got a hacker',
    ][Math.floor((Math.random() * 7))]);
  }, []);
  return (
    <>
      <GlobalStyles />
      <Seo title="RACER TRASH" titleTemplate="%s" />

      <TemporarySnowfall />

      <SiteHeading>
        <h1 data-text="RACER TRASH">
          RACER TRASH
          <span className="spark" />
        </h1>
      </SiteHeading>

      <OutrunBackground />

      <FritoLay />

      <CoinMeDaddy>
        <Link to="/work/">Insert coin to continue...</Link>
        <div dangerouslySetInnerHTML={{ __html: secretMessage }} />
      </CoinMeDaddy>
    </>
  );
}

export default IndexPage;
