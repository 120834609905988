import React from 'react';
import styled from 'styled-components';

const OutrunBackgroundContainer = styled.div`
  font-size: 3vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  .grid {
    position: absolute;
    bottom: -33.7%;
    left: 0;
    margin-left: -50%;
    width: 200%;
    height: 100%;
    background-image: repeating-linear-gradient(
        90deg,
        var(--grid-color, black) 0%,
        transparent calc(1px + var(--grid-blur, 0px)),
        transparent var(--grid-size),
        var(--grid-color, black)
          calc(var(--grid-size) + 1px + var(--grid-blur, 0px))
      ),
      repeating-linear-gradient(
        180deg,
        var(--grid-color, black) 0%,
        transparent calc(1px + var(--grid-blur, 0px)),
        transparent var(--grid-size),
        var(--grid-color, black)
          calc(var(--grid-size) + 1px + var(--grid-blur, 0px))
      );
    transform: perspective(50vh) rotateX(50deg) translateZ(10px);
    animation: moving-grid 500ms infinite linear;
    z-index: -1;
  }

  @keyframes moving-grid {
    0% {
      transform: perspective(50vh) rotateX(50deg) translateZ(10px)
        translateY(-var(--grid-size));
    }
    100% {
      transform: perspective(50vh) rotateX(50deg) translateZ(10px)
        translateY(var(--grid-size));
    }
  }

  .mountain {
    position: absolute;
    content: '';
    bottom: 35%;
    left: calc(50% + var(--mountain-offset, 0px));
    border-left: calc(var(--mountain-base) / 2) solid transparent;
    border-bottom: var(--mountain-height, 100px) solid
      var(--mountain-color1, white);
    border-top: 0px solid transparent;
    border-right: calc(var(--mountain-base, 100px) / 2) solid transparent;
    transform-origin: bottom;
    transform: skewX(var(--mountain-tilt, 0deg));
  }

  .mountain:after {
    content: '';
    border-left: calc(var(--mountain-base) / 2) solid transparent;
    border-bottom: var(--mountain-height, 100px) solid
      var(--mountain-color2, black);
    border-top: 0px solid transparent;
    border-right: calc(var(--mountain-base, 100px) / 2) solid transparent;
    transform: translate(-50%) scale(0.98);
    position: absolute;
    left: 0;
    top: 0;
  }

  .overlay {
    width: 100%;
    height: 100%;
    z-index: 9999;
    position: absolute;
    left: 0;
    top: 0;
    background-image: repeating-linear-gradient(
      rgba(0, 0, 0, 0.3) 0,
      transparent 1px,
      transparent 2px,
      rgba(0, 0, 0, 0.3) 3px
    );
    pointer-events: none;
  }

  .background-80s {
    background: linear-gradient(
        to bottom,
        #010310 0,
        #0c1142 24%,
        #45125e 45%,
        #d53567 60%,
        #f0c3d9 65%,
        #0c1142 65%
      )
      fixed;
    background-size: 100% var(--background-height, 100vh);
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: var(--background-height, 100vh);
    z-index: -2;
  }

  .background-80s:before {
    content: '';
    background: linear-gradient(
        to bottom,
        #010310 0,
        #0c1142 24%,
        #45125e 45%,
        #d53567 60%,
        #f0c3d9 65%,
        #0c1142 65%
      )
      fixed;
    width: 100%;
    height: 55%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    opacity: 0.2;
  }

  /*stars*/
  .stars:after {
    transform: translateY(-40%);
    content: ' ';
    border-radius: 100%;
    width: 3px;
    height: 4px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    box-shadow: 5vw 15vh 2px white, 1vw 33vh 0px white, 2vw 25vh 2px white,
      10vw 10vh 2px white, 12vw 20vh 0px white, 30vw 15vh 2px white,
      16vw 5vh 2px white, 24vw 10vh 0px white, 32vw 40vh 0px white,
      33vw 35vh 2px white, 12vw 38vh 2px white, 24vw 10vh 0px white,
      33vw 5vh 2px white, 20vw 10vh 0px white, 80vw 10vh 2px white,
      62vw 20vh 0px white, 60vw 15vh 2px white, 70vw 7vh 0px white,
      62vw 50vh 0px white, 65vw 35vh 2px white, 64vw 10vh 0px white,
      85vw 2vh 0px white, 92vw 40vh 0px white, 75vw 35vh 2px white,
      90vw 10vh 0px white;
    opacity: 0.3;
    animation: glitter 2s infinite;
  }

  @keyframes glitter {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.9;
    }

    100% {
      opacity: 0.5;
    }
  }
`;

const OutrunBackground = () => (
  <OutrunBackgroundContainer>
    <div
      className="background-80s stars"
      style={{
        '--background-height': '100vh',
      }}
    >
      <div
        className="grid"
        style={{
          '--grid-color': 'rgba(181, 34, 226,0.7)',
          '--grid-size': '30px',
          '--grid-blur': '1px',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '10vw',
          '--mountain-height': '5vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#b533b3',
          '--mountain-offset': '10vw',
          '--mountain-tilt': '-20deg',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '10vw',
          '--mountain-height': '5vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#681e6b',
          '--mountain-tilt': '59deg',
          '--mountain-offset': '20vw',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '8vw',
          '--mountain-height': '4vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#b533b3',
          '--mountain-offset': '20vw',
          '--mountain-tilt': '-20deg',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '3vw',
          '--mountain-height': '4vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#681e6b',
          '--mountain-tilt': '45deg',
          '--mountain-offset': '28vw',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '5vw',
          '--mountain-height': '5vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#681e6b',
          '--mountain-offset': '-40vw',
          '--mountain-tilt': '-20deg',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '5vw',
          '--mountain-height': '5vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#b533b3',
          '--mountain-tilt': '33deg',
          '--mountain-offset': '-35vw',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '5vw',
          '--mountain-height': '5vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#681e6b',
          '--mountain-tilt': '-62deg',
          '--mountain-offset': '-5vw',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '10vw',
          '--mountain-height': '5vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#2a025d',
          '--mountain-tilt': '-20deg',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '10vw',
          '--mountain-height': '5vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#150030',
          '--mountain-tilt': '59deg',
          '--mountain-offset': '10vw',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '10vw',
          '--mountain-height': '10vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#150030',
          '--mountain-tilt': '-33deg',
          '--mountain-offset': '-30vw',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '10vw',
          '--mountain-height': '10vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#2a025d',
          '--mountain-offset': '-20vw',
          '--mountain-tilt': '20deg',
        }}
      ></div>
      <div
        className="mountain"
        style={{
          '--mountain-base': '3vw',
          '--mountain-height': '10vw',
          '--mountain-color1': '#a684cb',
          '--mountain-color2': '#681e6b',
          '--mountain-tilt': '45.5deg',
          '--mountain-offset': '-10vw',
        }}
      ></div>

      <div className="overlay"></div>
    </div>
  </OutrunBackgroundContainer>
);

export default OutrunBackground;
