import React from 'react';
import styled from 'styled-components';
import frito2 from '../images/frito2.png';

const FritoLayContainer = styled.figure`
  margin: 40px auto;
  position: relative;
  height: 450px;
  width: 450px;
  background: rgb(var(--color-bg));
  background: #160e3e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translateZ(0);

  .stripes-wraper {
    width: 450px;
    height: 450px;
    border-radius: 50%;
    filter: drop-shadow(0 0 20px rgb(255, 128, 0));
    overflow: hidden;
    position: absolute;
  }

  .stripe {
    width: 100%;
    height: 5px;
    background-color: #fff;
    animation: scroll 1s linear infinite;
  }

  @keyframes scroll {
    from {
      transform: translateY(-10px);
    }
  }

  .stripe + .stripe {
    margin-top: 5px;
  }

  .gradient-mask {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #d92200, #ffd800);
    background-position: center;
    mix-blend-mode: multiply;
    position: absolute;
    border-radius: 50%;
  }

  .frito {
    width: 450px;
    height: 450px;
    background: bottom center url(${frito2});
    background-size: contain;
    position: absolute;
    border-radius: 50%;
  }

  &:hover {
    .stripes-wraper {
      filter: drop-shadow(0 0 20px #f29);
    }

    .gradient-mask {
      background: linear-gradient(to bottom, #92f 0%, #f29 100%);
    }

    .frito {
      animation: color-rotate 2s linear infinite;
    }
  }

  @keyframes color-rotate {
    from {
      filter: hue-rotate(0deg);
    }
    to {
      filter: hue-rotate(360deg);
    }
  }

  @media only screen and (max-width: 530px) {
    margin: auto;

    &,
    .stripes-wraper,
    .frito {
      width: calc(100vw - 80px);
      height: calc(100vw - 80px);
    }
  }
`;

const FritoLay = () => (
  <FritoLayContainer aria-label="animation of frito the cat">
    <div className="stripes-wraper">
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
      <div className="stripe"></div>
    </div>
    <div className="gradient-mask"></div>
    <div className="frito"></div>
  </FritoLayContainer>
);

export default FritoLay;
